import React from "react"
import Layout from "../components/layout"
import classes from "./projects.module.scss"
import Img from "gatsby-image"

import SEO from "../components/seo"

import { graphql } from "gatsby"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

export const query = graphql`
  query($locale: String!) {
    allContentfulProject(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          nazwa
          subname
          mockup {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const projects = props => {
  return (
    <Layout>
      <SEO title="Projects" />
      <div className={classes.projekty}>
        <div className={classes.projekty__itembox}>
          {props.data.allContentfulProject.edges.map(edge => {
            return (
              <Link
                to={`/projects/project/${edge.node.nazwa}`}
                className={`${classes.projekty__itembox__item} ${"animate"}`}
                data-animation="neo-background-1"
              >
                <div
                  className={`${
                    classes.projekty__itembox__item_img
                  } ${"animate"}`}
                  data-animation="fade"
                >
                  <Img
                    alt="zdjecie projektu"
                    fluid={edge.node.mockup.fluid}
                  ></Img>
                </div>

                <div className={classes.projekty__itembox__item__textbox}>
                  <p className={classes.projekty__itembox__item__subheading}>
                    {edge.node.nazwa}
                  </p>
                  <p className={classes.projekty__itembox__item__heading}>
                    {edge.node.subname}
                  </p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default projects
